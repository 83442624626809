import React from 'react';
import styled from 'styled-components';

interface Props {
  file: string;
  alt: string;
}

export default ({ file, alt }: Props) => (
  <DaiseeD>
    <img src={file} alt={alt} />
  </DaiseeD>
);

const DaiseeD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: 50%;
  width: 96px;
  height: 96px;
  padding: 1.5rem;
  margin: 0 auto;
`;
