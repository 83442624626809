import React from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
}

export default ({ text }: Props) => <H2>{text}</H2>;

const H2 = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey500};
  padding-bottom: 3rem;
  line-height: 1.5;
`;
