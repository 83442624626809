import React from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
}

export default ({ text }: Props) => <H1>{text}</H1>;

const H1 = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  padding-bottom: 2rem;
  letter-spacing: -1px;
  line-height: 1.25;

  @media all and (max-width: 26.563em) {
    font-size: 2rem;
  }
`;
