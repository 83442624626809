import React from 'react';
import styled from 'styled-components';
import GSN from '../../../../static/partners/gsn.png';
import Unity4 from '../../../../static/partners/unity4.svg';
import DaiseeD from '../../../assets/d_daisee.svg';
import LogoCircle from '../../LogoCircle';

const companyList = [
  {
    id: 'daisee',
    copy:
      'daisee builds technology that empowers people. daisee provides world-leading automated quality management of customer interactions, powered by artificial intelligence, speech and text analytics. Enabling you to see farbeyond words alone, surfacing the underlying emotion hidden deep within your customerinteractions – what your customers are really saying, thinking, and feeling. daisee helps people solve problems by making interactions simple and smart so they can have a more significant impact, be more productive, and be better at what they do.',
    link: 'https://daisee.com/',
    logo: DaiseeD,
  }
];

interface Company {
  id: string;
}

interface Props {
  companies: Company[];
}

export default ({ companies }: Props) => (
  <div>
    {companies.map((el) => {
      const company = companyList.find((x) => x.id === el.id);
      const name = company?.id || '';
      const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1);
      return (
        <div>
          <h3>About {nameCapitalized}</h3>
          <DWrapper>
            <LogoCircle file={company?.logo} alt={`${company?.id} logo`} />
          </DWrapper>
          <Copy>{company?.copy}</Copy>
          <Link href={company?.link} target="_blank" rel="noopener">
            {company?.link}
          </Link>
        </div>
      );
    })}
  </div>
);

const Copy = styled.p`
  font-size: 1.125rem;
`;

const DWrapper = styled.div`
  margin-bottom: 0;

  && * {
    margin-bottom: 0;
  }
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.green};
  text-decoration: underline;
`;
