import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ children }: PropsWithChildren<{}>) => <Content>{children}</Content>;

const Content = styled.main`
  margin-top: 2rem;

  *:nth-child(n) {
    margin-bottom: 2rem;
    line-height: 1.8;
  }
`;
