import React, { useContext } from 'react'
import { graphql, PageProps, Link } from 'gatsby'
import { LogIn, ArrowLeft, ArrowRight } from 'react-feather'
import styled, { ThemeContext } from 'styled-components'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import { sampleSize, shuffle } from 'lodash'
import { SeoObject, Site } from '../components/Site'
import H1 from '../components/BlogPosts/utils/H1'
import H2 from '../components/BlogPosts/utils/H2'
import H3 from '../components/BlogPosts/utils/H3'
import CTA from '../components/BlogPosts/utils/CTA'
import Emphasis from '../components/BlogPosts/utils/Emphasis'
import HeroImage from '../components/BlogPosts/utils/HeroImage'
import P from '../components/BlogPosts/utils/P'
import PullQuote from '../components/BlogPosts/utils/PullQuote'
import SeeMore, { FrontMatter } from '../components/BlogPosts/utils/SeeMore'
import Content from '../components/BlogPosts/utils/Content'
import BlogContainer from '../components/BlogPosts/utils/BlogContainer'
import Footer from '../components/Footer/Footer'
import Author from '../components/BlogPosts/utils/Author'
import WideImg from '../components/BlogPosts/utils/WideImg'
import Hr from '../components/BlogPosts/utils/Hr'
import About from '../components/BlogPosts/utils/About'
import Contact from '../components/BlogPosts/utils/Contact'
import { Button } from '../components/Button'
import { Main } from '../components/Styled'

interface Props {
  data: {
    post: {
      frontmatter: FrontMatter
      body: string
      fields: {
        readingTime: {
          text: string
        }
      }
    }
    allPosts: {
      edges: { node: { frontmatter: FrontMatter } }[]
    }
  }
}

const shortcodes = {
  H1,
  H2,
  H3,
  CTA,
  Emphasis,
  HeroImage,
  P,
  PullQuote,
  Content,
  BlogContainer,
  Author,
  WideImg,
  Hr,
  About,
  Contact
}

export default ({ data: { post, allPosts }, ...props }: Props & PageProps) => {
  const themeContext = useContext(ThemeContext)

  const seo: SeoObject = {
    title: post.frontmatter.title,
    image: post.frontmatter.featureImg.publicURL,
    description: post.frontmatter.metaDescription
  }

  const { prev, next }: any = props.pageContext

  return (
    <Site seo={seo} {...props}>
      <Main>
        <MDXProvider components={{ ...shortcodes }}>
          <Controls>
            <Link to='/blog/'>
              <Button
                action='back'
                btnHeight='2.5rem'
                icon={<LogIn style={{ transform: 'scale(-1,1)' }} />}
                iconSide='left'
                bgColor={themeContext.colors.grey400}
              />
            </Link>
            <PrevNextWrapper>
              {prev && (
                <Link to={`/blog/${prev.frontmatter.slug}/`}>
                  <Button
                    action='Previous'
                    btnHeight='2.5rem'
                    icon={<ArrowLeft />}
                    iconSide='left'
                    bgColor={themeContext.colors.green}
                  />
                </Link>
              )}
              {next && (
                <Link to={`/blog/${next.frontmatter.slug}/`}>
                  <Button
                    action='Next'
                    btnHeight='2.5rem'
                    icon={<ArrowRight />}
                    iconSide='right'
                    bgColor={themeContext.colors.green}
                  />
                </Link>
              )}
            </PrevNextWrapper>
          </Controls>

          <BlogWrapper>
            <MDXRenderer readingTime={post.fields.readingTime.text} frontmatter={post.frontmatter}>
              {post.body}
            </MDXRenderer>
          </BlogWrapper>

          <SeeMore posts={sampleSize(shuffle(allPosts.edges), 4)} />
        </MDXProvider>
      </Main>
      <Footer />
    </Site>
  )
}

const Controls = styled.div`
  max-width: 680px;
  margin: 0 auto;
  flex-grow: 1;
  padding: 9rem 1.5rem 0;
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 26.563em) {
    display: grid;
    gap: 1.5rem;
  }
`

const PrevNextWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  width: fit-content;
  grid-auto-flow: column;
  align-items: center;
`

const BlogWrapper = styled.div`
  transition: all 0.75s ease;
  position: relative;
  z-index: 5;
`

export const pageQuery = graphql`
  query MdxPosts($uid: String!) {
    post: mdx(frontmatter: { slug: { eq: $uid } }) {
      frontmatter {
        id
        title
        subtitle
        author
        date
        slug
        metaDescription
        featureImg {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          extension
          publicURL
        }
        authorImg {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        wideImg {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          extension
          publicURL
        }
      }
      body
      fields {
        readingTime {
          text
        }
      }
    }
    allPosts: allMdx(filter: { frontmatter: { slug: { ne: $uid }, type: { eq: "post" } } }) {
      edges {
        node {
          frontmatter {
            id
            title
            author
            slug
            featureImg {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              extension
              publicURL
            }
          }
        }
      }
    }
  }
`
