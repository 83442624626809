import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ children }: PropsWithChildren<{}>) => <P>{children}</P>;

const P = styled.p`
  font-size: 1.125rem;

  @media all and (max-width: 26.563em) {
    font-size: 1rem;
  }

  a {
    color: ${({ theme }) => theme.colors.green};
    text-decoration: underline;
  }

  li,
  ul {
    margin: 2rem 0;
    text-align: center;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.green};
  }
`;
