import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Img, { FluidObject } from 'gatsby-image';
import { Clock } from 'react-feather';
import { format } from 'date-fns'
import ShareLinks from '../../ShareLinks';
import daiseeD from '../../../assets/d_daisee.svg';

interface Props {
  imgSrc: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  authorName: string;
  date: string;
  title: string;
  readingTime: string;
}

export default ({ imgSrc, authorName, date, title, readingTime }: Props) => {
  const themeContext = useContext(ThemeContext);

  return (
    <AuthorWrapper>
      {imgSrc ? <StyledImg fluid={imgSrc.childImageSharp.fluid} alt="" /> : <AuthorAvatar src={daiseeD} />}
      <AuthorContainer>
        <AuthorCredentials>
          {authorName && <AuthorName>{authorName}</AuthorName>}
          <DateReadingTimeWrapper>
            <StyledDate>{format(new Date(date), 'LLL dd, yyyy')}</StyledDate>
            <Spacer>·</Spacer>
            <ReadingTime>
              <Clock size={16} color={themeContext.colors.grey700} />
              <StyledDate>{readingTime}</StyledDate>
            </ReadingTime>
          </DateReadingTimeWrapper>
        </AuthorCredentials>
        <ShareLinks text={title} />
      </AuthorContainer>
    </AuthorWrapper>
  );
};

interface StyledImgProps {
  fluid: FluidObject;
}

const StyledImg = styled(Img)<StyledImgProps>`
  border-radius: 50%;
  max-width: 4.5rem;
  max-height: 4.5rem;
  width: 4.5rem;
  margin-right: 1rem;
`;

const AuthorWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 3rem;

  @media all and (max-width: 31.25rem) {
    display: grid;
    gap: 1.5rem;
    justify-content: unset;
    align-items: unset;
  }
`;
const AuthorAvatar = styled.img`
  border-radius: 50%;
  max-width: 4.5rem;
  max-height: 4.5rem;
  width: 4.5rem;
  margin-right: 1rem;
`;
const AuthorContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  @media all and (max-width: 26.563em) {
    display: grid;
    gap: 1.5rem;
  }
`;
const AuthorCredentials = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const AuthorName = styled.h4`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey700};
`;

const StyledDate = styled.h4`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey700};
  font-size: 0.875rem;
`;

const DateReadingTimeWrapper = styled.time`
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: column;
  width: fit-content;
  align-items: center;

  @media all and (max-width: 31.25rem) {
    gap: 0.25rem;
  }
`;

const ReadingTime = styled.time`
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: column;
  width: fit-content;
  align-items: center;
`;

const Spacer = styled.span`
  color: ${({ theme }) => theme.colors.grey700};
`;
