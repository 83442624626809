import React from 'react';
import styled from 'styled-components';
import { FluidObject } from 'gatsby-image';
import getFluidOrSvg from '../../utils/getFluidOrSvg';

interface Props {
  imgSrc: {
    childImageSharp: {
      fluid: FluidObject;
    };
    extension: string;
    publicURL: string;
  };
  alt: string;
  caption?: string;
}

export default ({ imgSrc, alt, caption }: Props) => {
  return (
    <WideImg>
      {getFluidOrSvg({ imgSrc, alt })}
      {caption && <Caption>{caption}</Caption>}
    </WideImg>
  );
};

const WideImg = styled.figure`
  margin-bottom: 2rem;
  max-height: 500px;
  object-position: 50% 50%;
  overflow: hidden;
`;

const Caption = styled.figcaption``;
