import { Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import Container from '../../Container'

export interface FrontMatter {
  id: string
  title: string
  slug: string
  author: string
  date: string
  subtitle: string
  metaDescription: string
  featureImg: {
    childImageSharp: {
      fluid: FluidObject
    }
    extension: string
    publicURL: string
  }
  wideImg: {
    childImageSharp: {
      fluid: FluidObject
    }
    extension: string
    publicURL: string
  }
}

interface Props {
  posts: {
    node: {
      frontmatter: FrontMatter
    }
  }[]
}

export default ({ posts }: Props) => (
  <Container>
    <Title>More from daisee</Title>
    <SeeMore>
      {posts.map((post, i) => (
        <Post key={i}>
          <Description>
            <PostTitle>{post.node.frontmatter.title}</PostTitle>
            <Author>{post.node.frontmatter.author}</Author>
          </Description>
          <Link to={`/blog/${post.node.frontmatter.slug}/`}>
            <ImgWrapper>
              {post?.node?.frontmatter?.featureImg?.childImageSharp?.fluid ? (
                <Img fluid={post.node.frontmatter.featureImg.childImageSharp.fluid} alt='' />
              ) : (
                <img src={post.node.frontmatter.featureImg.publicURL} alt='' />
              )}
            </ImgWrapper>
          </Link>
        </Post>
      ))}
    </SeeMore>
  </Container>
)

const ImgWrapper = styled.figure`
  box-shadow: ${({ theme }) => theme.boxShadow.tiny};
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: ${({ theme }) => theme.boxShadow.small};
  }
`

const SeeMore = styled.div`
  display: grid;
  gap: 3rem 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  padding: 1.5rem 1.5rem 3rem;

  @media all and (max-width: 48em) {
    grid-template-columns: 1fr;
  }
`

const Title = styled.h4`
  font-weight: 500;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  font-size: 1.5rem;
  padding: 0 1.5rem 1.5rem;
`

const Post = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  gap: 1.5rem;

  @media all and (max-width: 26.563em) {
    grid-template-columns: 1fr;
  }
`

const Description = styled.div`
  display: grid;
  gap: 1.5rem;
  height: fit-content;
`

const PostTitle = styled.h5`
  font-weight: 600;
  font-size: 1.125rem;
`

const Author = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.grey700};
`
