import React from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
}

export default ({ text }: Props) => <H3>{text}</H3>;

const H3 = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey700};
  line-height: 1.5 !important;
  letter-spacing: -1px;

  @media all and (max-width: 26.563em) {
    font-size: 1.75rem;
  }
`;
