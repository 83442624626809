import React from 'react';
import styled from 'styled-components';

interface Person {
  name: string;
  email: string;
  mobile?: string;
  title: string;
}

interface Props {
  people: Person[];
}

export default ({ people }: Props) => (
  <ContactWrapper>
    {people.map((person) => (
      <PersonWrapper>
        <Name>{person.name}</Name>
        <Title>{person.title}</Title>
        <br />
        {person?.email && (
          <Title>
            Email — <Email href={`mailto:${person.email}`}>{person.email}</Email>
          </Title>
        )}
        {person?.mobile && <Title>Mobile — {person.mobile}</Title>}
      </PersonWrapper>
    ))}
  </ContactWrapper>
);

const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  && * {
    margin-bottom: 0;
  }

  @media all and (max-width: 48em) {
    grid-template-columns: 1fr;
  }
`;

const PersonWrapper = styled.div``;

const Name = styled.h4`
  font-weight: 600;
  font-size: 1.25rem;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.grey700};
`;

const Email = styled.a`
  color: ${({ theme }) => theme.colors.green};
`;
