import React from 'react'
import styled from 'styled-components'

interface Props {
  text: string
  nameTitle: string
}

export default ({ text, nameTitle }: Props) => {
  const newText = text.split('\n').join('<br />')

  return (
    <PullQuote>
      <Q dangerouslySetInnerHTML={{ __html: newText }} />
      <Author>{nameTitle}</Author>
    </PullQuote>
  )
}

const PullQuote = styled.div`
  display: grid;
  gap: 1.5rem;
  height: fit-content;
`

const Author = styled.p`
  font-weight: 500;
  margin-left: 1.5rem;
  @media all and (max-width: 26.563em) {
    margin-left: 0.75rem;
  }
`

const Q = styled.q`
  color: ${({ theme }) => theme.colors.grey500};
  line-height: 1.4 !important;
  font-size: 1.6rem;
  margin-left: 1.5rem;
  margin-bottom: 0 !important;

  @media all and (max-width: 26.563em) {
    font-size: 1.25rem;
    margin-left: 0.75rem;
  }
`
