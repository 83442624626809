import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ children }: PropsWithChildren<{}>) => <BlogContainer>{children}</BlogContainer>;

const BlogContainer = styled.main`
  padding-top: 6rem;
  max-width: 680px;
  margin: 0 auto;
  flex-grow: 1;
  padding: 1.5rem;
`;
