import React from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
}

export default ({ text }: Props) => <Emphasis>{text}</Emphasis>;

const Emphasis = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
  padding-top: 1.5rem;
  line-height: 1.5 !important;
`;
